import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`useSafeTimeout`}</inlineCode>{` is a utility Hook that allows safely calling `}<inlineCode parentName="p">{`setTimeout`}</inlineCode>{` and `}<inlineCode parentName="p">{`clearTimeout`}</inlineCode>{` within a component, ensuring that all timeouts are cleared when the component unmounts.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State>
  {([]) => {
    const {safeSetTimeout, safeClearTimeout} = useSafeTimeout()
    let timeoutId = null

    const handleOnClick = () => {
      timeoutId = safeSetTimeout(() => window.alert('hello!'), 5000)
    }

    const cancelTimeout = () => {
      safeClearTimeout(timeoutId)
    }

    return (
      <>
        <Button onClick={handleOnClick}>Click me</Button>
        <Button onClick={cancelTimeout}>Cancel timeout</Button>
      </>
    )
  }}
</State>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      